(()=>{
  const form = $('.form'),
        select = $('#select-group').find('select');
  select.on('change', function () {
    let formulario = $(this).val();
    $(formulario).fadeIn().siblings('form').fadeOut();
    $(formulario).fadeIn().siblings('form').find('input').removeAttr('required')
  });
  form.find('input, textarea').on('blur', function () {
    if($(this).val()!=""){
      $(this).parent().addClass('lleno');
    }else {
      $(this).parent().removeClass('lleno');
    }
  })
  form.on('submit', function (e) {
    e.preventDefault();
    let _t = $(this),
        nameForm = _t.attr('data-form');
    let data = $(this).serializeArray(),
        url = 'http://www.boinasnegras.com.mx/send.php';
    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: data
    });
    // .success(()=>{
    //   setTimeout(()=>{
    //     $('#success').fadeIn();
    //   },300)
    // }).error(()=>{
    //   setTimeout(()=>{
    //     $('#error').fadeIn();
    //   },300)
    // });
    (()=>{
      _t.find('button').text('').append('<i class="fa fa-spinner fa-spin"/>');
      setTimeout(()=>{
        $('#success').fadeIn();
        _t.find('button').find('i').remove();
        _t.find('button').text('enviado').attr('disabled','disabled').css('background','#ccc');
      },2000);
    })();
  })
})();
(()=>{
  const phone = $('.number');
  phone.on('keypress', (e)=>{
    let keynum = window.event ? window.event.keyCode : e.which;
    if ( keynum == 8 ) return true;
    return /\d/.test(String.fromCharCode(keynum));
  })
})();
