(function () {
    let vmodal = $('<div class="vmodal" id="vmodal"></div>');
    let vmodalCont = $('<div class="vmodal__container"></div>');
    let vmodalClose = $('<div class="vmodal__close icon-close" id="closemodal"></div>');
    let $lmodal = $('.modal');
    let $lmodaliframe = $('.modal-iframe');
    let body =$('body');
    vmodal.appendTo(body);
    // vmodal.hide();
    vmodalClose.appendTo(vmodalCont);
    vmodalCont.appendTo(vmodal);
    $lmodal.on('click',function (e) {
        e.preventDefault();
        let $t = $(this);
        let vinculo = $t.attr('href');
        let img = $('<img>');
        img.attr('src',vinculo);
        img.appendTo(vmodalCont);
        vmodal.fadeIn();
        body.css({
            // 'overflow': 'hidden'
        })
    });
    $lmodaliframe.on('click',function (e) {
        e.preventDefault();
        let $t = $(this);
        let vinculo = $t.attr('href');
        let iframe = $('<iframe>');
        vmodal.addClass("modal-iframe");
        iframe.attr('src',vinculo);
        iframe.appendTo(vmodalCont);
        vmodal.fadeIn();
        body.css({
            'overflow': 'hidden'
        })
    });
    vmodalClose.on('click', ()=>{
        let img = vmodalCont.find('img');
        let frame = vmodalCont.find('iframe');
        vmodal.fadeOut();
        setTimeout(()=>{
          vmodal.removeClass("modal-iframe");
            img.remove();
            frame.remove();

        },400);
        body.css({
            'overflow': 'auto'
        })
    });
    vmodal.on('click', function(e) {
        let _t = this;
        if (e.target !== _t)
            return;
        vmodalClose.trigger('click');
    });
})();
