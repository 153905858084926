(()=>{
  const mainMenu = $('#main-menu');
  mainMenu.on('click','a',function (e) {
    $('#main-nav-toggle').trigger('click')
    let link = $(this).attr('href');
    console.log(link);
    $('html, body').animate({
      scrollTop: $(link).offset().top
    },0);

  })
})();
(()=>{
  const header = $('#header');
  $(window).on('scroll', function (e) {
    var top = $(this).scrollTop(),
        height = $(window).height();
    if(top >= height){
      header.addClass('black');
    }else{
      header.removeClass('black');
    }
  })
})();
